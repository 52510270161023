import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Account } from './pages/account';
import { Calculator } from './pages/calculator';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/account' element={<Account/>} />
        <Route path='/calculator' element={<Calculator/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
