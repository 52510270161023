import { useEffect, useState } from "react"
import { CHAIN_ID } from "../constants";
import {getTotalSupply , getTokenPrice , getCirculatingSupply , getMarketCap , gettreasuryAsset , getPoolValue , getInsuranceValue , getTokeninfirpit , getUserBalance } from "../utils/contractHelper";

// const _1e5 = BigNumber.from(10).pow(OCTRO_DECIMALS);



export const useCommonStats = (update) => {
  const [stats, setStats] = useState({
    tokenPrice : 0,
    totalSupply: 0,
    circSupply: 0,
    marketcap : 0 ,
    treasuryAsset : 0,
    insurancevalue : 0 ,
    poolvalue : 0 ,
    firpitintoken : 0,
    firpitinusd : 0,
    firpitper : 0 ,
    balFurn: 0,
    balIns: 0,
    balTrea: 0,
    oBal_OB: 0,
    bBal_OB: 0,
    uBal_BU: 0,
    bBal_BU: 0,
    lastRebaseTime: Math.floor(new Date().getTime()/1000.0),
  });


  

  

  useEffect( () => {
      const fetch = async () => {
      let totalSupply = await getTotalSupply();
      let tokenPrice = await getTokenPrice();
      let circSupply = await getCirculatingSupply();
      let marketcap = await getMarketCap(); 
      let treasuryAsset_value = await gettreasuryAsset();
      let poolvalue = await getPoolValue();
      let insurace = await getInsuranceValue();
      let firpit = await getTokeninfirpit();
      
 

      setStats({
        tokenPrice : tokenPrice.toFixed(3),
        totalSupply :  totalSupply.toFixed(3),
        circSupply: circSupply.toFixed(3),
        marketcap : marketcap,
        treasuryAsset : treasuryAsset_value,
        poolvalue : poolvalue,
        insurancevalue : insurace,
        firpitintoken : firpit.firpitintoken,
        firpitinusd : firpit.firpitinusd,
        firpitper : firpit.firpitper,
        lastRebaseTime: Math.floor(new Date().getTime()/1000.0),
      });
    }

    
      fetch();
    
  }, [update]);

  return stats;
}

export const useAccountStats = (account, chainId, updater) => {
  const [stats, setStats] = useState({
    balance: 0
  });

  // const mc = useMulticallContract();

  useEffect(() => {
    const fetch = async () => {
      
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const balance = await getUserBalance(account);
      

      setStats({
        balance: balance
      })
    }

    if (account && chainId === CHAIN_ID) {
      fetch();
    }
  
  }, [account, updater , chainId]);

  return stats;
}
