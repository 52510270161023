import React, { useState } from "react";
import styled from 'styled-components';

const InputWrapper = styled.div`
  border-radius: 6px;
 background: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 1.1em;

  input {
    border: 0;
    outline: none;
    background: transparent;
    color: var(--text);
    flex: 1;
    display: block;
    font-weight: 400;
    font-size: 20px;
    min-width: 150px;
    color: var(--blue-color);
  }

  .btn {
    cursor: pointer;
    color: var(--blue-color);
    font-weight: 600;
    font-size: inherit;

    &:hover {
      color: var(--blue-color);
    }
  }
`;

export const NumberInput = (props) => {
  const { value, onChange, btnLabel, btnValue } = props;

  const [value2, setValue2] = useState(value);

  const onInputChange = (e) => {
    setValue2(e.target.value);
    onChange && onChange(e.target.value);
  }

  const setDefValue = () => {
    setValue2(btnValue);
    onChange && onChange(btnValue);
  }

  return <InputWrapper>
    <input {...props} type="number" value={value2} onChange={onInputChange} />
    {btnLabel && <span className="btn" onClick={() => setDefValue()} >{btnLabel}</span>}
  </InputWrapper>
}