import {  faTelegram ,faGithub } from "@fortawesome/free-brands-svg-icons";
import { faBook, faBorderAll, faCalculator, faCircleUser, faExchange } from "@fortawesome/free-solid-svg-icons";


export const menuItems = [
  {
    link: '/',
    title: 'Dashboard',
    icon: faBorderAll
  },
  {
    link: '/account',
    title: 'Account',
    icon: faCircleUser
  },
  {
    link: '/calculator',
    title: 'Calculator',
    icon: faCalculator
  },
  {
    link: 'https://365swap.exchange/swap?outputCurrency=0x43aE89101b1cE6875528A7139be90249b1812b04',
    title: 'Swap',
    icon: faExchange
  },
  {
    link: 'https://block365.gitbook.io/riserocket/',
    title: 'Docs',
    icon: faBook
  }
]

export const socialLinks = [
  {
    link: 'https://github.com/',
    icon: faGithub,
    name: 'github'
  },
  // {
  //   link: '',
  //   icon: faTwitter,
  //   name: 'twitter'
  // },
  // {
  //   link: '',
  //   icon: faReddit,
  //   name: 'reddit'
  // },
  {
    link: 'https://telegram.org/',
    icon: faTelegram,
    name: 'Telegram'
  },

];
