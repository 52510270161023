import { InjectedConnector } from "@web3-react/injected-connector";
import { BigNumber } from "ethers";

export const CHAIN_ID = 56;

export const getRpcUrl = () => {
  return {
    56: 'https://bsc-dataseed.binance.org/',
   
  }[CHAIN_ID]
}

export const injected = new InjectedConnector({
  supportedChainIds: [56 ,97 ,1 , 3 , 5 , 80001 , 127 ]
})

export const RISEROCKET_DECIMALS = 5;
export const BN_ZERO = BigNumber.from(0);

export const _1e18 = BigNumber.from(10).pow(18);
