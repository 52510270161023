import React, { useEffect, useState } from "react";
import { Page } from "../page";
import styled from 'styled-components';
import { NumberInput } from "../../components/numberInput";
import { Range } from "react-range";
import { Box as Box0, Container } from "../home";
import { useAccountStats, useCommonStats } from "../../web3s/hooks/useStats";
import { formatPrice , numberFormat } from "../../web3s/utils/calc";
import { APY } from "../../constants";
import useActiveWeb3React from "../../web3s/hooks/useWeb3";

const Box = styled(Box0)`
  h2 {
    font-weight: 700;
  }

  .box-2 {
    display: flex;
    background: var(--body-bg);
    align-items: center;
    height: 100%;
    padding: 20px 20px;
    border-radius: 10px;
    width: 100%;
    flex-wrap: wrap;
    img {
      margin-right: 15px;
    }
    @media(max-width: 380px){
      
      justify-content: center;
      > div{
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 10px;
      }
      label, p{
        text-align: center;
      }
     

    }
    label {
      display: block;
      font-weight: 700 !important;
      margin-top: 0px;
      font-family: "gilmer-heavy";
      font-size: 14px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .inputs {
    label {
      color:  var(--box-color);
      font-weight: 400;      
    }
    width: 100%;
  }
`

const Value = styled.p`
  font-weight: 700;
  margin-bottom: 0;
  font-size: 18px;
  color:  var(--box-color);
`;

const SliderTrack = styled.div`
  border: 1px solid var(--body-bg);
  height: 10px;
  border-radius: 10px;
  background: var(--box-color);
`;

// Circle
const SliderThumb = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #ff0191;
`;

export const Calculator = () => {

  const [days, setDays] = useState(50);

  const [updater] = useState(1);

  const { account, chainId } = useActiveWeb3React();

  const stats = useCommonStats(updater);
  const myStats = useAccountStats(account, chainId, updater);

 

  const [calcAmount, setCalcAmount] = useState(0);
  const [calcAPY, setCalcAPY] = useState(APY);
  const [calcPrice, setCalcPrice] = useState(0);
  const [calcFPrice, setCalcFPrice] = useState(0);
  // const [invest, setInvest] = useState(0);
  // const [rewardEst, setRewardEst] = useState(0);
  // const [preturn, setPreturn] = useState(0);

  const [dr, setDr] = useState(1);

  useEffect(() => {
    setDr(Math.pow(calcAPY / 100, 1 / 365));
  }, [calcAPY]);

  return <Page>
    <Container >
      <Box className="px-2 px-md-5" >
        <h2>
          Estimate Your Returns
        </h2>
        <div className="row no-gutters  mb-4 features">
          <div className="col-12 col-sm-4 col-lg-4 col-xxl-4">

            <div className="box-2">
              <img src="/images/11.png" alt="" />
              <div>
                <label>RISEROCKET Price</label>
                <Value>${numberFormat(parseFloat(stats.tokenPrice))}</Value>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-lg-4 col-xxl-4">
            <div className="box-2">
              <img src="/images/22.png" alt="" />
              <div>
                <label>Current APY</label>
                <Value>{formatPrice(APY, 1)}%</Value>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-lg-4 col-xxl-4">
            <div className="box-2">
              <img src="/images/55.png" alt="" /><br/>
              <div>
                <label>Your RISEROCKET Balance</label>
                <Value>{formatPrice(myStats.balance, 1)} RISEROCKET</Value>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4 text-start inputs">
          <div className="col-12 mb-3">
            <label>RISEROCKET Amount</label>
            <NumberInput onChange={v => setCalcAmount(v)} btnLabel='Max' btnValue={myStats.balance} value={myStats.balance} />
          </div>
          <div className="col-12 mb-3">
            <label>APY(%)</label>
            <NumberInput onChange={v => setCalcAPY(v)} btnLabel='Current' btnValue={APY} value={APY} />
          </div>
          <div className="col-12 mb-3">
            <label>RISEROCKET price at purchase ($)</label>
            <NumberInput onChange={v => setCalcPrice(v)} btnLabel='Current' btnValue={stats.tokenPrice} value={stats.tokenPrice} />
          </div>
          <div className="col-12 mb-3">
            <label>Future RISEROCKET market price ($)</label>
            <NumberInput onChange={v => setCalcFPrice(v)} btnLabel='Current' btnValue={stats.tokenPrice} value={stats.tokenPrice} />
          </div>
        </div>

        <div className="mb-2 content-details" >
          Duration ({days} days)
        </div>
        <div className="px-2 content-details">
          <Range
            step={1}
            min={1}
            max={365}
            values={[days]}
            onChange={(values) => setDays(values[0])}
            renderTrack={({ props, children }) => <SliderTrack {...props} >{children}</SliderTrack>}
            renderThumb={({ props }) => <SliderThumb {...props} />}
          />
        </div>

        <div className="mt-4 mb-2 content-details">
          <div className="d-flex justify-content-between mt-4" >
            <span>Your initial investment</span>
            <span>${formatPrice(calcAmount * calcPrice)}</span>
          </div>
          <div className="d-flex justify-content-between mt-3" >
            <span>Current wealth</span>
            <span>${formatPrice(calcAmount * stats.tokenPrice)}</span>
          </div>
          <div className="d-flex justify-content-between mt-3" >
            <span>RISEROCKET rewards estimation</span>
            <span>{formatPrice(Math.pow(dr, days) * calcAmount, 1)} RISEROCKET</span>
          </div>
          <div className="d-flex justify-content-between mt-3" >
            <span>Potential return</span>
            <span>${formatPrice(Math.pow(dr, days) * calcAmount * calcFPrice)}</span>
          </div>
        </div>
      </Box>
    </Container>
  </Page>
}