import { ethers } from "ethers";
import { simpleRpcProvider } from ".";
import {tokenAddress ,wbnbAddress , riserocketBnbLpAddress ,lpAbi ,tokenAbi ,decimal , bnbBusdLpAddress , commonAbi , busdAddress, treasuryAddress , insuranceAddress , firpitaddress} from '../contracts';




export const getContract = (abi, address, signer = undefined) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}


export const getContractBalance = async(abi, address, userAddresss , signer = undefined) =>{
  try{
    const signerOrProvider = signer ?? simpleRpcProvider
    let contarct =  new ethers.Contract(address, abi, signerOrProvider);
    let balance = await contarct.balanceOf(userAddresss);
    return balance;
  }
  catch(err){
    return false;
  }
}


export const getTotalSupply = async (signer = undefined) =>{
  try{
    const signerOrProvider = signer ?? simpleRpcProvider
    let contarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
    let balance = await contarct.totalSupply();
    balance = parseFloat(balance.toString()) / Math.pow(10,decimal); 
    return balance;
  }
  catch(err){
    return false;
  }
}

 
export const getTokenPrice =async (signer = undefined) =>
{
  const signerOrProvider = signer ?? simpleRpcProvider;
  let lpbnb_contract =  new ethers.Contract(bnbBusdLpAddress, lpAbi, signerOrProvider);
    
    let reserve = await lpbnb_contract.getReserves();
    let bnb_token_price = parseFloat(reserve._reserve1.toString() / reserve._reserve0.toString());
    
    let token_contract =  new ethers.Contract(tokenAddress, commonAbi, signerOrProvider);
    let wbnb_contract =  new ethers.Contract(wbnbAddress, commonAbi, signerOrProvider);
    
    let wbnb_balance = await wbnb_contract.balanceOf(riserocketBnbLpAddress);
    wbnb_balance = parseFloat(wbnb_balance.toString() / Math.pow(10,18));
    let lp_balance = await token_contract.balanceOf(riserocketBnbLpAddress);
    lp_balance = parseFloat(lp_balance.toString() / Math.pow(10,5));
    
    let token_price = parseFloat((wbnb_balance * bnb_token_price) / lp_balance);
    
    return isNaN(token_price) ? 0 : token_price;
  
}


export const getCirculatingSupply = async(signer = undefined) =>
{
  try{
    const signerOrProvider = signer ?? simpleRpcProvider
    let contarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
    let balance = await contarct.getCirculatingSupply();
    balance = parseFloat(balance.toString()) / Math.pow(10,decimal); 
    return  isNaN(balance) ? 0 : balance;
  }
  catch(err){
    return false;
  }
}



export const getMarketCap = async (signer = undefined) =>
{
  const signerOrProvider = signer ?? simpleRpcProvider
  let token_contarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);

  let total_supply = await getTotalSupply();
  let burn = await  token_contarct.balanceOf('0x000000000000000000000000000000000000dEaD');
  burn = burn / Math.pow(10,decimal);
  let token_price = await  getTokenPrice();
  let marketCap = parseFloat((total_supply - burn) * token_price).toFixed(3);
  return  isNaN(marketCap) ? 0 : marketCap;
}


export const gettreasuryAsset = async(signer = undefined) =>{
  const signerOrProvider = signer ?? simpleRpcProvider
  let tokencontarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
  let busdcontarct =  new ethers.Contract(busdAddress, tokenAbi, signerOrProvider);
  let token_price = await getTokenPrice();
  let token_value = await tokencontarct.balanceOf(treasuryAddress);
  token_value = token_value.toString() / Math.pow(10,decimal);
  token_value = parseFloat(token_value * token_price)
  let busd_value = await busdcontarct.balanceOf(treasuryAddress);
  busd_value = busd_value.toString() / Math.pow(10,18);

  let treasuryasset = parseFloat(token_value) + parseFloat(busd_value);
  return  isNaN(treasuryasset) ? 0 : treasuryasset;  
}


export const getPoolValue = async(signer = undefined) =>{
  const signerOrProvider = signer ?? simpleRpcProvider
  let tokencontarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
  let busdcontarct =  new ethers.Contract(wbnbAddress, tokenAbi, signerOrProvider);
  let token_price = await getTokenPrice();
  let token_value = await tokencontarct.balanceOf(riserocketBnbLpAddress);
  token_value = token_value.toString() / Math.pow(10,decimal);
  token_value = parseFloat(token_value * token_price)
  let busd_value = await busdcontarct.balanceOf(riserocketBnbLpAddress);
  busd_value = busd_value.toString() / Math.pow(10,18);

  let lpbnb_contract =  new ethers.Contract(bnbBusdLpAddress, lpAbi, signerOrProvider);
    
  let reserve = await lpbnb_contract.getReserves();
  let bnb_token_price = parseFloat(reserve._reserve1.toString() / reserve._reserve0.toString());
  busd_value = parseFloat(busd_value * bnb_token_price)

  let poolvalue = parseFloat(token_value) + parseFloat(busd_value);
  return isNaN(poolvalue) ? 0 : poolvalue; 
}


export const getInsuranceValue = async(signer = undefined) =>{
  const signerOrProvider = signer ?? simpleRpcProvider
  let busdcontarct =  new ethers.Contract(busdAddress, tokenAbi, signerOrProvider);
 
  let busd_value = await busdcontarct.balanceOf(insuranceAddress);
  busd_value = busd_value.toString() / Math.pow(10,18);

  
  return isNaN(busd_value.toFixed(3)) ? 0 : busd_value.toFixed(3); 
}


export const getTokeninfirpit = async (signer = undefined) =>{
  const signerOrProvider = signer ?? simpleRpcProvider
  let tokencontarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
 
  let busd_value = await tokencontarct.balanceOf(firpitaddress);
  let firpitintoken = busd_value.toString() / Math.pow(10,decimal);

  let token_price = await getTokenPrice();

  let firpitinusd = parseFloat(firpitintoken * token_price);
  firpitinusd = isNaN(firpitinusd) ? 0 : firpitinusd

  let total_supply = await getTotalSupply();

  let firpitper = parseFloat((firpitintoken * 100) / total_supply);
  firpitper = firpitper.toFixed(2); 
  return {firpitintoken , firpitinusd , firpitper  }; 
}


export const getUserBalance = async(address,signer = undefined ) =>{
  const signerOrProvider = signer ?? simpleRpcProvider
  let tokencontarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
 
  let balance = await tokencontarct.balanceOf(address);
   balance = balance.toString() / Math.pow(10,decimal);

   return balance.toFixed(3);
}


export const nextRebase = async (signer = undefined) =>{
  
  const signerOrProvider = signer ?? simpleRpcProvider
  let tokencontarct =  new ethers.Contract(tokenAddress, tokenAbi, signerOrProvider);
 
  let lastrebase = await tokencontarct._lastRebasedTime();
  lastrebase = lastrebase.toString();

   return lastrebase;
}




